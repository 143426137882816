import React, { useEffect } from "react"
import Helmet from "react-helmet"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import "./tos.css"; // Import CSS file here


export default function Terms() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);  return (
        <HomepageLayout>
            <Helmet data={JSONData}><title>Terms of Service</title></Helmet>
            <header>
                <img src="/img/mainlogo.png" alt="Main Logo" />
            </header>
            <div
                name="termly-embed"
                data-id="3ea98818-a1b5-4d76-9070-38d20f25207a"
                data-type="iframe"
            ></div>
            <RawFooter />
        </HomepageLayout>
    );
}
